import { action, computed, makeObservable, observable } from 'mobx';

import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { IMapNode, IMenuOption, IPlace } from './domain';
import { getStateFips } from '../utils/get-state-fips';
import { mapFromAllowlist, mapToSelectedFips } from './mappers';

const flattenMapNodes = (nodes: Record<string, IMapNode>): IPlace[] => {
  return Object.values(nodes).reduce((places: IPlace[], node: IMapNode) => {
    const { id, label, children, depth, element, isDisabled } = node;

    const place: IPlace = {
      fips: id,
      name: label,
      level: depth,
      element,
      isDisabled,
    };

    places.push(place);

    if (children) {
      const childPlaces = flattenMapNodes(children);
      places.push(...childPlaces);
    }

    return places;
  }, []);
};

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  private mapNodes: Record<string, IMapNode> = {};
  allPlaces: IPlace[] = [];
  selectedPath: IPlace[] = [];

  allowlist: string[] = [];
  selectedState = '';
  selectedCounty = '';
  selectedFips = '';

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    makeObservable(this, {
      allowlist: observable,
      allPlaces: observable,
      selectedPath: observable,
      selectedOption: computed,
      currentLevel: computed,
      placeOptions: computed,
      selectedState: observable,
      selectedCounty: observable,
      selectedFips: observable,

      goBack: action.bound,
      setMapNodes: action.bound,
      setPlace: action.bound,
      setPlaceByFips: action.bound,
    });

    this.synchronizeFromCapi('allowlist', 'Sim.Allowlist', mapFromAllowlist);
    this.synchronizeToCapi('selectedState', 'Sim.SelectedState');
    this.synchronizeToCapi('selectedCounty', 'Sim.SelectedCounty');
    this.synchronizeToCapi(
      'selectedFips',
      'Sim.SelectedFips',
      mapToSelectedFips
    );
  }

  private setSelectedPlace() {
    if (this.currentLevel > 1) {
      this.selectedState = this.selectedPath[0].name;
      this.selectedCounty = this.selectedPath[1].name.replace(/,.*/g, '');
      this.selectedFips = this.selectedPath[1].fips;
    } else {
      this.selectedState = '';
      this.selectedCounty = '';
      this.selectedFips = '';
    }
  }

  setPlace(place: IPlace | null): void {
    if (place === null) {
      this.selectedPath = [];
      this.setSelectedPlace();
      return;
    }

    if (
      place.level === 0 &&
      this.selectedPath.length > 0 &&
      this.mapNodes[place.fips].id !== this.selectedPath[0].fips
    ) {
      this.selectedPath = [place];
      return;
    }

    const parentNode = this.mapNodes[getStateFips(place.fips)];

    if (
      place.level === 1 &&
      parentNode &&
      parentNode.id !== this.selectedPath[0]?.fips
    ) {
      const { depth, id, label, element } = parentNode;

      this.selectedPath[0] = {
        fips: id,
        name: label,
        level: depth,
        element,
      };
    }

    this.selectedPath[place.level] = place;
    this.setSelectedPlace();
  }

  setPlaceByFips(fips: string) {
    const place = this.allPlaces.find((p) => p.fips === fips) || null;

    this.setPlace(place);
  }

  setMapNodes(mapNodes: Record<string, IMapNode>): void {
    this.mapNodes = mapNodes;

    this.allPlaces = flattenMapNodes(mapNodes);
  }

  goBack(): void {
    if (this.selectedPath.length > 0) {
      this.selectedPath.pop();
      this.setSelectedPlace();
    }
  }

  get selectedOption(): IMenuOption | null {
    if (this.selectedPath.length === 0) {
      return null;
    }

    const currentEl = this.selectedPath[this.currentLevel - 1];

    return {
      value: currentEl.fips,
      label: currentEl.name,
      isDisabled: Boolean(currentEl.isDisabled),
    };
  }

  get placeOptions(): IMenuOption[] {
    return this.allPlaces.map((place) => ({
      value: place.fips,
      label: place.name,
      isDisabled: Boolean(place.isDisabled),
    }));
  }

  get currentLevel(): number {
    return this.selectedPath.length;
  }
}
