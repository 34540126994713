import React, { FC, PropsWithChildren, useState } from 'react';
import { ITabProps } from 'asu-sim-toolkit';
import classNames from 'classnames';

export interface IButtonProps extends PropsWithChildren, ITabProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;

  onClick(): void;
}

export const Button: FC<IButtonProps> = ({
  children,
  onClick,
  autoFocus = false,
  className,
  inputRef,
  onKeyDown,
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <button
      autoFocus={autoFocus}
      disabled={disabled}
      className={classNames(
        'focus:outline-none focus:ring focus:ring-hint flex items-center justify-center px-[10px] py-[6px] truncate text-md font-semibold',
        disabled && 'opacity-70 cursor-not-allowed',
        isFocused && 'z-10',
        className
      )}
      type="button"
      onClick={onClick}
      ref={inputRef}
      onKeyDown={onKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {children}
    </button>
  );
};
