import React from 'react';
import { Spinner } from '../../components/Spinner/Spinner';

export const LoadingScreen = () => (
  <div className="h-screen w-screen relative bg-gray">
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spinner scale={2.5} />
      <div className="mt-4 font-normal text-center">
        The app is loading. Please wait.
      </div>
    </div>
  </div>
);
