import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.Allowlist': string;
  'Sim.SelectedState': string;
  'Sim.SelectedFips': string;
  'Sim.SelectedCounty': string;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.Fullscreen.Visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Allowlist': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.SelectedState': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.SelectedFips': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.SelectedCounty': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
