import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Select, { components, MenuListProps, SingleValue } from 'react-select';
import classNames from 'classnames';

import { MapSvg } from './MapSvg';
import { useStores } from '../../providers/store/use-stores';
import { IMapNode, IMenuOption, IPlace } from '../../stores/domain';
import COLORS from '../../colors.json';
import { Button } from '../Button/Button';
import { Icon } from '../Icon';

export interface IState {
  name: string;
  counties: {
    [fips: string]: string;
  };
}

const LimitedMenuList: FC<MenuListProps<IMenuOption, false>> = ({
  children,
  ...props
}) => (
  <components.MenuList {...props}>
    {Array.isArray(children) ? children.slice(0, 30) : children}
  </components.MenuList>
);

const CLICK_EVENT_TARGET_SELECTOR = `svg#map_widget g#counties`;
const LOWEST_LEVEL = 1;
const LOWEST_LEVEL_SELECTOR = 'svg>g#counties>g';

const selectorByLevel: ((place: IPlace) => string | null)[] = [
  (place) => `svg#map_widget g#${place.name} path`,
  (place) => `svg#map_widget g path#c${place.fips}`,
];

export const Map: FC = observer(() => {
  const {
    appStore: {
      allowlist,
      placeOptions,
      setMapNodes,
      selectedPath,
      setPlaceByFips,
      currentLevel,
      selectedOption,
      goBack,
    },
  } = useStores();
  const [inputValue, setInputValue] = useState('');
  // NOTE: This scans the map, extracts counties, maps them into dropdown options and sends them
  //       to the store.
  // TODO: This code is specific to the USA counties map. In the future, we should make the map
  //       more generic (with consistent IDs and --data-labels)
  useLayoutEffect(() => {
    const stateElements = [...document.querySelectorAll(LOWEST_LEVEL_SELECTOR)];

    const mapNodes = stateElements.reduce<Record<string, IMapNode>>(
      (acc, stateEl) => {
        const stateName = stateEl.id.replace(/_/g, ' ');
        const countyElements = [...stateEl.querySelectorAll('path')];

        const stateFIPS = countyElements[0]?.id.slice(1, 3);

        const stateNode: IMapNode = {
          id: stateFIPS,
          label: stateName,
          depth: 0,
          parent: null,
          element: stateEl,
          children: {},
        };

        const countyNodes = countyElements.reduce<Record<string, IMapNode>>(
          (countyAcc, countyEl) => {
            const countyFIPS = countyEl.id.slice(1);

            const isAllowed = allowlist.includes(countyFIPS);

            if (isAllowed || allowlist.length === 0) {
              countyEl.classList.remove('countyDisabled');
            } else {
              countyEl.classList.add('countyDisabled');
            }

            const isCountyDisabled = allowlist.length > 0 ? !isAllowed : false;

            const name =
              countyEl
                .querySelector('title')
                ?.textContent?.replace(/_/g, ' ') || '???';

            const countyNode: IMapNode = {
              id: countyFIPS,
              label: name.replace(/,.*/g, `, ${stateName}`),
              depth: 1,
              parent: stateNode,
              element: countyEl,
              children: {},
              isDisabled: isCountyDisabled,
            };

            return { ...countyAcc, [countyFIPS]: countyNode };
          },
          {}
        );

        stateNode.children = countyNodes;

        return { ...acc, [stateFIPS]: stateNode };
      },
      {}
    );

    setMapNodes(mapNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowlist]);

  useLayoutEffect(() => {
    const handleClick = (ev: MouseEvent) => {
      if (ev.target === null) {
        return;
      }
      const el = ev.target as Element;

      if (currentLevel === 0) {
        setPlaceByFips(el.id.slice(1, 3));
      } else {
        setPlaceByFips(el.id.slice(1));
      }
    };

    document.body
      .querySelector<SVGSVGElement>(CLICK_EVENT_TARGET_SELECTOR)
      ?.addEventListener('click', handleClick);

    return () => {
      document.body
        .querySelector<SVGSVGElement>(CLICK_EVENT_TARGET_SELECTOR)
        ?.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLevel]);

  const handleSelectChange = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    if (!newValue) {
      throw new Error('Selected value does not exist.');
    }

    setPlaceByFips(newValue.value);
  };

  const deepestSelectedPlace = selectedPath?.at(-1);

  const styles =
    selectedPath.length === LOWEST_LEVEL + 1 && deepestSelectedPlace
      ? `${selectorByLevel[selectedPath.length - 1](
          deepestSelectedPlace
        )} { fill: ${COLORS.blue.DEFAULT} }`
      : '';

  const deepestElement = selectedPath.at(-1)?.element;

  const viewBox = useMemo(() => {
    if (!deepestElement) {
      return;
    }

    const bb = (deepestElement as SVGSVGElement).getBBox();

    return `${bb.x - 10} ${bb.y - 10} ${bb.width + 20} ${bb.height + 20}`;
  }, [deepestElement]);

  const isZoomedIn = currentLevel > 0;

  return (
    <div className="relative w-full h-full flex flex-col gap-[20px] items-center">
      <div
        className={classNames(
          'w-full h-full absolute pointer-events-none inset-0 transition-shadow duration-500',
          isZoomedIn && 'shadow-inner'
        )}
      />
      {selectedPath.length > 0 && (
        <style
          dangerouslySetInnerHTML={{
            __html: styles,
          }}
        />
      )}
      <div className="absolute top-[15px] left-1/2 transform -translate-x-1/2 w-[94%] max-w-[400px] h-[38px] flex items-center justify-center mx-auto rounded-lg shadow-outer">
        {currentLevel > 0 && (
          <Button
            className={classNames(
              'text-white bg-blue border border-blue rounded-l-lg h-full'
            )}
            onClick={goBack}
          >
            <Icon id="chevron-left" />
          </Button>
        )}
        <Select
          inputValue={inputValue}
          onInputChange={(newValue) => setInputValue(newValue)}
          menuIsOpen={Boolean(inputValue)}
          defaultValue={null}
          isSearchable
          tabSelectsValue={false}
          backspaceRemovesValue
          value={selectedOption}
          options={placeOptions}
          placeholder="Type to search..."
          onChange={handleSelectChange}
          isOptionDisabled={(option) => option.isDisabled}
          components={{ MenuList: LimitedMenuList }}
          className="basic-single w-full max-w-[400px] rounded-md"
          styles={{
            control: (base, state) => ({
              ...base,
              border: 0,
              borderTopRightRadius: '0.375rem',
              borderBottomRightRadius: '0.375rem',
              borderTopLeftRadius: isZoomedIn ? 0 : '0.375rem',
              borderBottomLeftRadius: isZoomedIn ? 0 : '0.375rem',
              boxShadow: state.isFocused
                ? `0 0 0 3px ${COLORS.hint.DEFAULT}`
                : 'none',
              '&:hover': {
                border: 0,
              },
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected
                ? COLORS.blue.DEFAULT
                : state.isFocused
                ? COLORS.blue.light
                : 'transparent',
            }),
          }}
        />
      </div>
      <MapSvg viewBox={viewBox} />
    </div>
  );
});
